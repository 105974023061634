import i18next from "i18next";
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

import Translate_En from './Translations/English/translate_En.json';
import Translate_Ku from './Translations/Kurdish/translate_Ku.json';
import Translate_Ar from './Translations/Arabic/translate_Ar.json';

i18next
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    debug: true,
    lng: 'ku', // Default language, changed from 'lan' to 'lng'
    fallbackLng: 'ku', // Fallback language if no translation is found
    resources: {
      ku: {
        translation: Translate_Ku // Changed 'transition' to 'translation'
      },
      ar: {
        translation: Translate_Ar // Changed 'transition' to 'translation'
      },
      en: {
        translation: Translate_En // Changed 'transition' to 'translation'
      },
    },
    interpolation: {
      escapeValue: false // React already escapes by default
    }
  });

export default i18next;
