import FiveBackground from '../../../assect/Toxic3D.png'
import SpecialBackground from '../../../assect/lines.png'
import SpecialBackgroundPhone from '../../../assect/linesPhone.svg'

import { useTranslation } from 'react-i18next'

const Special = () => {
    const {t,i18n} = useTranslation();
    const currentLanguage = i18n.language;

    const PragraphText = [
        t('MainPage.Special.p2'),
        t('MainPage.Special.p3'),
        t('MainPage.Special.p4'),
        t('MainPage.Special.p5'),
        t('MainPage.Special.p6'),
        t('MainPage.Special.p7'),
        t('MainPage.Special.p8'),
        t('MainPage.Special.p9'),
        t('MainPage.Special.p10'),
    ]
    return (
        <div className="sm:my-32 my-52 w-full h-full relative">
            <div className="mx-auto w-10/12 h-10/12 relative">
                <p className=" font-Rabar_037  lg:text-2xl md:text-lg max-md:text-xl max-sm:text-lg max-md:text-center" dir={(currentLanguage==='en')?'ltr':'rtl'}>{t('MainPage.Special.title')}</p>
                <div className="relative  flex md:flex-row flex-col-reverse justify-center max-md:items-center rounded-[20px] 2xl:py-1  max-md:pt-10 max-md:pb-3 border-[0.75px] lg:mt-8 mt-5 shadow-[0_0_21.1px_0_rgba(0,0,0,0.8),_inset_6px_4px_35.3px_-16px_rgba(255,255,255,0.25)] backdrop-blur-[104.8px]">
                    <div className='relative lg:w-[48%] md:w-7/12 w-full h-auto flex flex-col md:justify-between  items-center max-md:pt-8 '>
                        <p className='font-Rabar_037  lg:text-2xl md:text-lg max-md:translate-y-7 max-md:text-xl xl:translate-y-10 lg:translate-y-11 md:translate-y-12 '>{t('MainPage.Special.header')}</p>
                        <img src={FiveBackground} alt='' className='xl:w-[30rem] xl:h-[30rem] lg:w-[25rem] lg:h-[25rem] md:w-[21rem] md:h-[21rem] max-md:w-3/4 max-md:h-80 max-sm:h-60 object-cover -rotate-[8.33deg] xl:-translate-y-6 lg:-translate-y-2 lg:-translate-x-14 md:-translate-x-16 max-sm:translate-y-3' />
                        <p className='font-Rabar_037  lg:text-2xl md:text-lg text-center max-md:-translate-y-3 max-md:text-xl xl:-translate-y-20 lg:-translate-y-14 md:-translate-y-12'>{t('MainPage.Special.get')}</p>

                    </div>
                    <div className="font-Rabar_037 xl:text-2xl lg:text-xl md:text-lg lg:w-[57%] md:w-4/6 w-full md:ps-10 lg:py-8 my-auto max-md:text-center max-md:text-xl max-sm:text-lg blur-text" dir='rtl'>
                        <p className=''>{t('MainPage.Special.p1')}</p>
                        {
                            PragraphText.map((item,index)=>(
                                <p className='md:leading-loose leading-snug' key={index}>{item}</p>
                            ))
                        }
                    </div>
                </div>
            </div>
            <p className="font-Rabar_037 lg:text-2xl md:text-lg max-md:text-xl text-center px-3 text-shadow-example mt-5" >{t('MainPage.Special.bottom')}</p>
            <img src={SpecialBackground} alt="" className="absolute xl:-top-[25rem] lg:-top-40 md:-top-40 xl:-left-[36rem] lg:-left-96 md:-left-72 max-md:-top-36 max-md:-left-40 max-sm:-left-28 xl:w-[100rem] xl:h-[100rem] max-md:hidden -z-20"/>
            <img src={SpecialBackgroundPhone} alt="" className="absolute md:hidden -top-48 -z-20"/>
        </div>
    );
}

export default Special;