import { useTranslation } from "react-i18next";
const ColorButton = ({ getColor, EventColor }) => {
    const {t} = useTranslation();

    const SqureColor = [
        { name: 'Dots', valueColor: getColor.Dots, titleText: t('Design.QrCode.ButtonQrCode.Dot') },
        { name: 'Background', valueColor: getColor.Background, titleText: t('Design.QrCode.ButtonQrCode.Background') },
        { name: 'CornersSquare', valueColor: getColor.CornersSquare, titleText: t('Design.QrCode.ButtonQrCode.CornersSquare') },
        { name: 'cornersDot', valueColor: getColor.cornersDot, titleText: t('Design.QrCode.ButtonQrCode.cornersDot') },
    ]
    
    return (
        <div className="mt-5 flex flex-row lg:gap-x-5 md:gap-x-2 gap-x-5"  >
            {
                SqureColor.map((item, index) => (
                    <div className="flex flex-col items-center gap-y-4 h-auto md:w-14 w-9" key={index}>
                        <div className="lg:size-[68px] md:size-14 size-11 bg-white flex items-center justify-center rounded-md">
                            <div className="relative lg:size-14 md:size-11 size-9">
                                <input
                                    className="absolute inset-0 h-full w-full opacity-0 "
                                    name={item.name}
                                    type="color"
                                    onChange={EventColor}
                                    value={item.valueColor}
                                />
                                <div
                                    className="absolute inset-0 bg-white  rounded-md pointer-events-none"
                                    style={{ backgroundColor: item.valueColor }}
                                ></div>
                            </div>
                        </div>
                        <p className='md:text-sm text-xs text-center'>{item.titleText}</p>
                    </div>
                ))
            }
        </div>
    );
}

export default ColorButton;