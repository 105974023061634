import { useTranslation } from "react-i18next";

const TypeOfQrCode = [
    'square', 'rounded', 'dots', 'classy',
    'classy-rounded', 'extra-rounded'
]
const TypeAndImageOfQrCode = ({isHeddin, setIsHeddin, EventColor, getColor ,setImage}) => {
    const {t} = useTranslation();

    const HandlingIMAGE = (event) => {
        const file = event.target.files[0];
        const reader = new FileReader();

        reader.onloadend = () => {
            setImage(reader.result); // Set the image data URL for QR code rendering
        };

        if (file) {
            reader.readAsDataURL(file); // Read the file and convert it to a Data URL
        }
    };

    return (
        <div className="flex flex-col max-lg:items-end  gap-y-4">
            <div className="lg:pe-0 md:pe-6 max-xs:me-[13%] flex gap-x-2">
                <p className="font-Rabar_038  lg:text-2xl md:text-lg max-xs:text-xs">{t('Design.QrCode.ChooseStyle')}</p>
                <select id="typeQrCode" className="bg-black md:text-xl xs:text-sm text-[10px] xs:px-2 px-1 xs:w-32 w-24 rounded-md truncate " value={getColor.typeQrCode} name='typeQrCode' onChange={EventColor}>
                    {
                        TypeOfQrCode.map((item, index) => {
                            return (
                                <option key={index} value={item} className="">{item}</option>
                            )
                        })
                    }
                </select>
            </div>
            <div className="flex gap-x-2">
                <p className="font-Rabar_038 lg:text-2xl md:text-lg max-xs:text-xs">{t('Design.QrCode.image')}</p>
                <div className="flex gap-x-3">
                    <div>
                        <input type="file" className="hidden" id="file" onChange={HandlingIMAGE} accept="image/png, image/jpeg, image/svg, image/webp" />
                        <label htmlFor="file" className="bg-black xl:text-2xl md:text-lg text-xs rounded-lg px-4  py-1">choose a file</label>
                    </div>
                    <div className="flex gap-x-3 items-center">
                        <label htmlFor="hidden" className="max-lg:hidden">{t('Design.QrCode.hidden')}</label>
                        <input type="checkbox" id="hidden" className="size-[22px] accent-[rgb(212,30,67)]" checked={isHeddin} onChange={() => setIsHeddin(!isHeddin)} />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default TypeAndImageOfQrCode;