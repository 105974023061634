import Home from "./Home/Home";
import About from "./About";
import ShapesGradient from '../../assect/svg/shapes-gradient.svg';
import Marquee from "./Marquee/Marquee";
import Manager from "./Manager/Manager";
import Video from "./Video/Video";
import Special from "./Special/Special";
import Address from "./Address/address";
import Background from "./Home/BackgroundShap";
import { motion } from 'framer-motion'
import { useEffect, useState } from "react";

const AnimationMarquee = {
    move: {
        y: [10, -10],
        transition: {
            duration: 3.25,
            ease: 'easeInOut',
            repeat: Infinity,
            repeatType: 'reverse'

        }
    }
}

const AnimationRouter = {
    exit: {
        x: '100vw',
        transition: {
            ease: 'easeInOut'
        }
    }
}



const Main = () => {
    const [scrollY, setScrollY] = useState(0); // Initialize state to track scroll position

    // Effect to listen for scroll events and update the scrollY state
    useEffect(() => {
        const handleScroll = () => {
            setScrollY(window.scrollY); // Update state with the current scroll position
        };

        window.addEventListener('scroll', handleScroll); // Add scroll event listener

        return () => {
            window.removeEventListener('scroll', handleScroll); // Cleanup event listener on component unmount
        };
    }, []);
    return (
        <motion.div className="" variants={AnimationRouter} exit='exit'>
            <div className="relative w-full overflow-hidden">
                <Home />
                <Background changeScrollY={scrollY}/>
            </div>
            <Video />
            <About />
            <Special />
            <div className="relative w-full h-auto lg:pt-32 overflow-hidden">
                <motion.div variants={AnimationMarquee} animate='move' className="absolute md:-right-44 max-md:-left-16 md:top-0 top-8 max-md:blur-sm -z-20">
                    <img src={ShapesGradient} alt="" className="md:w-3/4 md:h-3/4 w-1/2 h-1/2" />
                </motion.div>
                <Marquee />
            </div>
            <Manager />
            <Address />
        </motion.div>
    );
}

export default Main;

