import { Swiper, SwiperSlide } from 'swiper/react'; 
import { Autoplay } from 'swiper/modules';

import SliderButton from './SliderButton';
import {motion} from 'framer-motion';

import 'swiper/css';
import 'swiper/css/navigation'; 
import { useTranslation } from 'react-i18next';

const animatimeRangeen  = {
    hidden:{
        opacity:0
    },
    visible:{
        opacity:1,
        transition:{
            type:'spring',
            duration:3.25,
            ease:'easeInOut'
        }
    }
}

const Home = () => { 
    const {t} = useTranslation();
    const GetImage = [];
    const Polygon = [];
    for (let i = 1; i <= 12; i++) {
        GetImage.push(require(`../../../assect/SliderHome/s${i}.jpg`))
    }
    for (let i = 1; i <= 8; i++) {
        Polygon.push(require(`../../../assect/svg/Polygon/Polygon-0${i}.svg`))
    }
    return (
        <div className='w-full h-auto z-30 relative select-none'>
            <div className="w-full h-auto md:py-24 py-[90px] relative overflow-hidden ">
                <div className="md:w-[94%] w-[98%] h-auto rounded-2xl mx-auto shadow-[0_0_21.1px_0_rgba(0,0,0,0.8),_inset_6px_4px_35.3px_-16px_rgba(255,255,255,0.25)] border backdrop-blur-[104px] relative">
                    <div className='md:my-2'>
                        <Swiper
                            modules={[Autoplay]}
                            slidesPerView={1}
                            loop
                            navigation={{
                                prevEl: '.prev',
                                nextEl: '.next',
                            }}
                            autoplay={{
                                delay: 4000,
                                disableOnInteraction: false,
                            }}
                            className='relative md:mt-6 rounded-2xl '
                        >
                            {
                                GetImage.map((item, index) => {
                                    return (
                                        <SwiperSlide className='rounded-2xl' key={index}>
                                            <div className='md:w-[97%] w-full mx-auto md:my-0.5 4xl:h-[60rem] 3xl:h-[50rem] 2xl:h-[40rem] xxl:h-[32rem] xl:h-[470px] lg:h-[390px] md:h-[410px] sm:h-96 h-72  backdrop-blur-lg rounded-2xl overflow-hidden'>
                                                <img src={item} alt={`Slider rsm ${index}`} className='w-full xl:h-auto xl:-translate-y-40 xl:scale-90 h-full  bg-cover ' />
                                            </div>
                                        </SwiperSlide>
                                    )
                                })
                            }
                            <SliderButton />
                        </Swiper>
                    </div>
                    <motion.div variants={animatimeRangeen} initial='hidden' animate='visible' className='absolute xl:bottom-[62px] lg:bottom-20 md:bottom-[72px] z-40 xl:left-2 lg:left-0.5 flex flex-col max-md:hidden'>
                        {
                            Polygon.map((item, index) => {
                                return (
                                    <img src={item} key={index} alt="" />
                                )
                            })
                        }
                    </motion.div>
                    <div className='flex justify-center backdrop:blur-[21px]'>
                        <p className='inline-block text-white  md:text-2xl text-sm font-Rabar_038 xl:my-8 my-5 z-10 blur-text-home' id='text-slide-home'>{t('MainPage.Home.BottomTitle')}</p>
                    </div>
                    <motion.div variants={animatimeRangeen} initial='hidden' animate='visible' className='absolute xl:-bottom-[180px] xl:-right-16 md:-bottom-32 md:-right-14 sm:-bottom-[85px] sm:right-0 -right-10 -bottom-16'>
                        <img src={require('../../../assect/svg/shapes+logo.png')} alt="The Rangeen Background" className='xl:w-96 xl:h-96 md:w-64 md:h-64 sm:w-40 sm:h-40 w-32 h-32' />
                    </motion.div>
                </div>  
            </div>
        </div>
    );
}

export default Home;