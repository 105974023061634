import Marquee from 'react-fast-marquee';
const M1 = () => {
    const Marquee3 = [
        { getURL: require('../../../assect/parLogos/M-01/m-01.png'), getStyle: 'lg:ml-10 max-md:-me-20 max-md:-ms-14 max-sm:-me-10' },
        { getURL: require('../../../assect/parLogos/M-01/m-02.png'), getStyle: 'lg:ml-10 max-md:-me-16 max-sm:-me-5' },
        { getURL: require('../../../assect/parLogos/M-01/m-03.png'), getStyle: 'lg:ml-10 md:me-3 max-md:-me-5 max-sm:me-5' },
        { getURL: require('../../../assect/parLogos/M-01/m-04.png'), getStyle: 'lg:ml-10 md:-me-6 max-sm:me-10' },
        { getURL: require('../../../assect/parLogos/M-01/m-05.png'), getStyle: 'lg:ml-10 md:-me-5 max-md:-ml-24 max-sm:me-10' },
        { getURL: require('../../../assect/parLogos/M-01/m-06.png'), getStyle: 'lg:ml-10 md:-mx-3 max-md:-ml-32 max-sm:me-10' },
    ]
    return (
        <Marquee className='lg:mt-16 mt-12' autoFill direction>
            {
                Marquee3.map((item, index) => (
                    <span className={item.getStyle} key={index}>
                        <img src={item.getURL} alt='' className='lg:w-auto lg:h-auto md:w-4/5 md:h-4/5 w-3/5 h-3/5' />
                    </span>
                ))
            }
        </Marquee>
    );
}

export default M1;