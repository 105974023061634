import React from 'react';
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';
import { useEffect, useState, useMemo } from 'react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/effect-coverflow';
import 'swiper/css/pagination';


// import required modules
import { Autoplay, EffectCoverflow, Pagination } from 'swiper/modules';

const Video = () => {
    const [videoDurations, setVideoDurations] = useState([]);

    const SliderVideo = useMemo(() => [
        { Video: require('../../../assect/vids/IMG_01.MOV'), title: "video 1", },
        { Video: require('../../../assect/vids/IMG_02.MOV'), title: "video 1", },
        { Video: require('../../../assect/vids/IMG_03.MOV'), title: "video 1", },
        { Video: require('../../../assect/vids/IMG_04.MOV'), title: "video 1", },
        { Video: require('../../../assect/vids/IMG_05.MOV'), title: "video 1", },
        { Video: require('../../../assect/vids/IMG_06.MOV'), title: "video 1", },
        { Video: require('../../../assect/vids/IMG_07.MOV'), title: "video 1", },
        { Video: require('../../../assect/vids/IMG_08.MOV'), title: "video 1", },
    ], []);

    useEffect(() => {
        getVideoDurations(SliderVideo).then(durations => {
            setVideoDurations(durations);
        });
    }, [SliderVideo]);

    const getVideoDurations = (videos) => {
        return Promise.all(
            videos.map(video => {
                return new Promise(resolve => {
                    const videoElement = document.createElement('video');
                    videoElement.src = video.Video;

                    videoElement.addEventListener('loadedmetadata', () => {
                        resolve({
                            title: video.title,
                            duration: videoElement.duration * 1000, // duration in milliseconds
                            src: video.Video
                        });
                    });
                });
            })
        );
    };

    return (
        <div className='w-full h-auto lg:my-24 my-16 '>
            <Swiper
                effect={'coverflow'}
                centeredSlides = {true}
                slidesPerView={2}
                coverflowEffect={{
                    rotate: 50, // Keep slides flat (no rotation)
                    stretch:0, // Increase space between active and non-active slides
                    depth: 100, // Adjust depth to keep non-active slides visible
                    modifier: 1.5,

                }}
                
                pagination={{
                    clickable: true,
                }}
                modules={[EffectCoverflow, Pagination, Autoplay]}
                autoplay={{
                    delay: videoDurations[0]?.duration || 5000, // Default to 5 seconds if durations not loaded
                    disableOnInteraction: false,
                }}
                loop={true}
                onSlideChange={(swiper) => {
                    const index = swiper.realIndex;
                    const delay = videoDurations[index]?.duration || 5000; // Set delay based on video duration
                    swiper.params.autoplay.delay = delay;
                    swiper.autoplay.start();
                }}
                className="mySwiper"
            >
                {
                    SliderVideo.map((item, index) => (
                        <SwiperSlide key={index}>
                            <video
                                playsInline
                                loop
                                autoPlay
                                muted
                                preload="auto"
                                className={`${(index === 2) ? 'w-full object-contain ' : 'w-full object-cover'} mx-auto  lg:h-[360px] md:h-72 sm:h-60 h-40  mb-10 `}
                            >
                                <source src={item.Video} type="video/mp4" />
                            </video>
                        </SwiperSlide>
                    ))
                }

            </Swiper>
        </div>
    );
}

export default Video;
