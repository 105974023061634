import T1 from '../../../assect/svg/t1.svg';
import T2 from '../../../assect/svg/t2.svg';
import T3 from '../../../assect/svg/elips.svg';
import { motion } from 'framer-motion';

const Background = ({ changeScrollY }) => {
    // Calculate rotation style
    const rotateStyle = {
        transform: `rotate(${changeScrollY/7}deg)`,
    };

    return ( 
        <motion.div style={rotateStyle} className="relative w-full h-full -z-40">
            <div className='absolute xxl:-top-[920px] xl:-top-[900px] lg:-top-[785px] lg:-left-52 md:-top-[780px] md:-left-32 sm:-top-[780px] -top-[700px] -left-80 -z-50'>
                <img src={T1} alt="" className='lg:w-full lg:h-full md:w-9/12 md:h-9/12 sm:w-10/12 sm:h-11/12 w-9/12 h-9/12' />
            </div>
            <div className='absolute xl:-bottom-48 xl:left-52 md:-bottom-24 md:left-44 xs:-bottom-8 bottom-0 left-20 -z-10'>
                <img src={T2} alt="" className=' xl:w-full xl:h-full md:w-10/12 md:h-10/12 sm:w-5/12 sm:h-5/12 w-9/12 h-9/12' />
            </div>
            <div className='absolute xxl:-top-[820px] xl:-top-[780px] lg:-top-[670px] md:-top-[700px] lg:-right-10 md:-right-40 sm:-top-[800px] -top-[650px] xs:-right-[330px] -right-[400px] -z-10'>
                <img src={T3} alt="" className=' lg:w-full lg:h-full md:w-9/12 md:h-9/12 sm:w-8/12 sm:h-8/12 w-[55%] h-[55%]' />
            </div>
        </motion.div>
    );
}

export default Background;
