import { useTranslation } from 'react-i18next';
import Rec01 from '../../assect/svg/RangenAbout/Rec-01.svg';
import Rec02 from '../../assect/svg/RangenAbout/Rec-02.svg';
import Rec03 from '../../assect/svg/RangenAbout/Rec-03.svg';
import Rec04 from '../../assect/svg/RangenAbout/blurRect.svg';
import Rangeen01 from '../../assect/svg/logoWireframe01.svg';
import {motion} from 'framer-motion';
const AnimationImage = {
    hidden:{
        y:80,
        opacity:0
    },
    visible:{
        y:0,
        opacity:1,
        transition:{
            type:'spring',
            duration:135,
            ease:'easeInOut'
        }
    }
}
const About = () => {
    const {t , i18n} = useTranslation();
    const currentLanguage = i18n.language;
    return (
        <div className='w-full h-auto relative max-sm:overflow-hidden' id='Information'>
            <div className='md:h-40 h-28'></div>
            <div className="w-full h-auto flex justify-center max-md:flex-col max-md:items-center gap-x-20 gap-y-5 lg:pb-5 py-1 md:px-20 text-white " >
            <img src={Rangeen01} alt="" className='absolute md:-bottom-5 -bottom-16 md:right-60 right-10 rotate-12 -z-10'/>
            <img src={Rangeen01} alt="" className='absolute md:bottom-20 md:left-1/3 bottom-24 left-10 -z-10 opacity-35 rotate-12'/>
            <motion.div variants={AnimationImage} initial='hidden' whileInView='visible' className="relative w-[310px] h-[310px] group ">
                <img src={Rec01} alt="" className='absolute lg:bottom-2 md:bottom-14 md:left-3 bottom-3 right-10 z-10 md:group-hover:-left-14 xs:group-hover:-right-12 group-hover:-right-3 group-hover:-rotate-[40deg] lg:group-hover:bottom-7 md:group-hover:bottom-20 group-hover:bottom-10 transition-all duration-500 ' />
                <img src={Rec02} alt="" className='absolute lg:-bottom-3 md:bottom-9 md:left-[50px] -bottom-3 right-4  z-10 lg:group-hover:-bottom-10 md:group-hover:-bottom-5  md:group-hover:left-20 transition-all duration-300 group-hover:right-10 group-hover:-bottom-10' />
                <img src={Rec04} alt="" className='absolute top-0 object-cover group-hover:scale-105 transition-all duration-300' />
                <img src={Rec03} alt="" className='absolute ' />
            </motion.div>
            <div className="relative w-3/5 max-md:text-center z-auto">
                <p className='font-Rabar_037 mt-10 md:text-2xl text-xl blur-text' dir={(currentLanguage==='en')?'ltr':'rtl'}>{t('MainPage.Us.title')}</p>
                <p className='font-Rabar_038 blur-text mt-9 lg:text-xl md:text-lg text-sm leading-relaxed text-pretty z-auto' dir={(currentLanguage==='en')?'ltr':'rtl'}> {t('MainPage.Us.text1')} <span className='text-[#D41E43]'> {t('MainPage.Us.year')} </span> {t('MainPage.Us.text2')}</p>
            </div>
        </div>
        </div>
        
    );
}

export default About;