import img1 from '../FileDownload/EleDesignProjects/cleaning-service-square-flyer-social-media-post-instagram-banner-template/374.jpg';
import img2 from '../FileDownload/EleDesignProjects/flat-design-help-wanted-labels/7537812.jpg';
import img3 from '../FileDownload/EleDesignProjects/food-menu-restaurant-social-media-banner-template/food_banner_33.jpg';
import img4 from '../FileDownload/EleDesignProjects/gradient-hiring-poster-flyer-template/8523340.jpg';
import img5 from '../FileDownload/EleDesignProjects/modern-business-corporate-letterhead-template/letterhead_15.jpg';

import aiLogo from '../assect/Design/ai.svg';
import psdLogo from '../assect/Design/psd.svg';

import SliderDes from './SliderDes';
import { useEffect, useContext } from 'react';
import { Context } from '../MainDesign';
import { useTranslation } from 'react-i18next';

const GetImage = [
    {
        getImage: img1,
        Download: require('../FileDownload/EleDesignProjects/cleaning-service-square-flyer-social-media-post-instagram-banner-template/374.psd'),
        TypeLogo: aiLogo,
        title:'The project file is Adobe illustrator'
    },
    {
        getImage: img2,
        Download: require('../FileDownload/EleDesignProjects/flat-design-help-wanted-labels/7537810.ai'),
        TypeLogo: psdLogo,
        title:'The project file is Adobe photoshop'
    },
    {
        getImage: img3,
        Download: require('../FileDownload/EleDesignProjects/food-menu-restaurant-social-media-banner-template/food_banner_33.psd'),
        TypeLogo: psdLogo,
        title:'The project file is Adobe photoshop'
    },
    {
        getImage: img4,
        Download: require('../FileDownload/EleDesignProjects/gradient-hiring-poster-flyer-template/8523338.ai'),
        TypeLogo: aiLogo,
        title:'The project file is Adobe illustrator'
    },
    {
        getImage: img5,
        Download: require('../FileDownload/EleDesignProjects/modern-business-corporate-letterhead-template/letterhead_15.psd'),
        TypeLogo: psdLogo,
        title:'The project file is Adobe photoshop'
    },
];

const Design = () => {
    const [getModel , setModel] = useContext(Context);
    const {t,i18n} = useTranslation();
    const currentLanguage = i18n.language;

    function EventModel(item){
        setModel({
            getURL :item ,
            Show : true
        })
    }
    useEffect(()=>{
        if(getModel.Show){
            document.body.style.overflow = 'hidden'
        }else{
            document.body.style.overflow = 'auto'
        }
        
        return () => {
            document.body.style.overflow = 'auto';
        };
    },[getModel.Show])

    return (
        <div  className='mt-32' dir={(currentLanguage==='en')?'ltr':'rtl'}>
            <p className='font-Rabar_038 text-2xl lg:ms-20 md:ms-14 sm:ms-10 ms-7'><span className='border-b-2 ps-2 pe-4 pb-1'>{t('Design.headerDesign')}</span></p>
            <SliderDes GetImage={GetImage} EventModel={EventModel}/>
        </div>
    );
};

export default Design;
