import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import { motion } from 'framer-motion';

const slideVariants = {
    hidden: { opacity: 0, x: 50 },  // Slide starts off the screen
    show: { opacity: 1, x: 0 ,transition:{
        delay:0.5,ease:'easeInOut',duration: 0.5, type: "spring"
    }},     // Slide moves into view
};

const SliderDes = ({ GetApps }) => {
    return (
        <div className='lg:ms-16 md:ms-10 sm:ms-6 ms-3'>

            <Swiper
                slidesPerView={2}
                breakpoints={{
                    1800: {
                        slidesPerView: 10,
                        spaceBetween: -20
                    },
                    1100: {
                        slidesPerView: 6.5,
                        spaceBetween: 20
                    },
                    900: {
                        spaceBetween: 100,
                        slidesPerView: 5.5
                    },
                    800: {
                        spaceBetween: -80,
                        slidesPerView: 4
                    },
                    700: {
                        spaceBetween: 20,
                        slidesPerView: 3.5
                    },
                    500: {
                        spaceBetween: 20,
                        slidesPerView: 3
                    },
                    400: {
                        spaceBetween: 20,
                        slidesPerView: 2.5
                    },
                    340: {
                        spaceBetween: -10,
                        slidesPerView: 2
                    },
                }}
                spaceBetween={10}
                grabCursor={true}
                className='mt-5 w-full h-full '
            >
                {
                    GetApps.map((item, index) => (
                        <SwiperSlide className='relative p-5' key={index}>
                            <motion.div variants={slideVariants} initial='hidden' whileInView='show' className='flex flex-col sm:w-[163px] sm:h-52 w-36 h-48 rounded-[20px] border-[0.75px] justify-center items-center shadow-[0_0_21.1px_0_rgba(0,0,0,0.8),_inset_6px_4px_35.3px_-16px_rgba(255,255,255,0.25)] backdrop-blur-[32.8px] relative '>
                                <img src={item.imageIcon} alt="Fun Design" className=' w-1/3 h-1/3' />
                                <p className='sm:text-xl text-lg font-Rabar_038 mt-2 mb-2'>{item.title}</p>
                                <a
                                    href={item.download}
                                    download
                                    className='sm:text-lg text-sm font-Rabar_038 bg-[rgb(212,30,67)] text-white sm:px-[15%] px-4  py-1 rounded-[6px] '>
                                        Download
                                </a>
                            </motion.div>
                        </SwiperSlide>
                    ))
                }
            </Swiper>
        </div>
    );
}

export default SliderDes;