import { NavLink } from "react-router-dom";
import { useTranslation } from "react-i18next";

const List = ({ GetStyle }) => {
    const { t, i18n } = useTranslation();
    const currentLanguage = i18n.language;

    return (
        <div>
            <ul className={`${GetStyle} cursor-none group`} id="NavbarLink" dir={currentLanguage !== 'en' ? 'ltr' : 'rtl'}>
                <NavLink to='/' >
                    <li className="group-hover:font-Rabar_038 hover:font-Rabar_037">{t('Navbar.List.Home')}</li>
                </NavLink>
                <a href='/#Information' >
                    <li className="group-hover:font-Rabar_038 hover:font-Rabar_037">{t('Navbar.List.Info')}</li>
                </a>
                <NavLink to='/AboutUs' >
                    <li className="group-hover:font-Rabar_038 hover:font-Rabar_037">{t('Navbar.List.About')}</li>
                </NavLink>
                <NavLink to='/Design'>
                    <li className="group-hover:font-Rabar_038 hover:font-Rabar_037">{t('Navbar.List.Design')}</li>
                </NavLink>
            </ul>
        </div>
    );
};

export default List;
