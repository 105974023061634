import { useSwiper } from "swiper/react";
import Button from '../../../assect/Button/Button.svg'

const SliderButton = () => {
    const swiper = useSwiper();
    const ButtonSwiper = [
        {
            button: function () { swiper.slidePrev() },
            DirButton: "rotate-180",
            title: "The Previce Button"
        },
        {
            button: function () { swiper.slideNext() },
            getStyle: 'max-md:-me-[14px]',
            title: "The next Button"
        },
    ]
    return (
        <div className="absolute top-1/2 flex justify-between xl:px-7 sm:px-7 px-3 w-full z-10">
            {
                ButtonSwiper.map((item, index) => {
                    return (
                        <button onClick={item.button} className={`cursor-pointer ${item.getStyle}`} key={index}>
                            <img src={Button} alt={item.title} className={`${item.DirButton} 3xl:w-12 3xl:h-12 max-md:w-9/12 max-md:h-9/12 max-sm:w-7/12 max-sm:h-7/12`} />
                        </button>
                    )
                })
            }
        </div>
    );
}

export default SliderButton;