import { useTranslation } from 'react-i18next';
import AddressImg from '../../../assect/shapes+logo-03.png';
import RangeLogo_01 from '../../../assect/svg/theRofRangeen.svg';
import Footer from '../Footer/Footer';
import { Link } from 'react-router-dom';
import {motion} from 'framer-motion';
const AnimationImage = {
    hidden:{
        x:100,
        opacity:0
    },
    visible:{
        x:0,
        opacity:1,
        transition:{
            type:'spring',
            duration:200,
            ease:'easeInOut'
        }
    }
}

const Address = () => {
    const {t} = useTranslation();
    return (
        <div className="w-full h-auto relative mt-32 overflow-hidden">
            <img src={RangeLogo_01} alt="" className="absolute sm:top-20 max-sm:-bottom-36 left-0 w-full h-full -z-20" />
            <div className="w-full h-full flex max-md:flex-col-reverse justify-center lg:gap-x-40 md:gap-x-20 max-md:gap-y-5 pb-20">
                <div>
                    <div className='h-full w-full flex flex-col justify-between items-center max-md:gap-y-7 md:py-10 font-Rabar_037 xl:text-3xl lg:text-2xl md:text-lg max-md:text-xl'>
                        <p>{t('MainPage.Address.Name')}</p>
                        <p>{t('MainPage.Address.Address')}</p>
                        <Link to='/AboutUs'>
                            <button className='cursor-none font-Rabar_037 xl:text-3xl lg:text-2xl md:text-lg max-md:text-xl py-2 px-20 bg-[rgb(212,30,68)]  border border-black rounded-[212px] outline outline-1 outline-transparent md:shadow-[0px_0px_100px_25px_rgba(212,30,68,0.81)] shadow-[0px_0px_50px_15px_rgba(212,30,68,0.81)] active:bg-[rgba(255,255,255,0.7)] md:-translate-y-10 max-md:translate-y-4 hover:bg-[#060606] hover:text-[rgb(212,30,68)] hover:ring-[rgb(212,30,68)] hover:ring-4'
                            >{t('MainPage.Address.us')}
                            </button>
                        </Link>
                    </div>
                </div>
                <div className='relative xl:w-[29rem] xl:h-[29rem] md:w-96 md:h-96 sm:h-80 sm:w-80 h-72 w-72 max-md:mx-auto cursor-pointer' >
                    <div className='xl:w-[29rem] xl:h-[29rem] md:w-96 md:h-96 sm:h-80 sm:w-80 h-72 w-72 max-md:mx-auto'>
                        <motion.a variants={AnimationImage} initial='hidden' whileInView='visible' href='https://goo.gl/maps/JMRsSXaQd1dkGm7SA' className='absolute inset-0'>
                            <img src={AddressImg} alt="" className='object-cover h-full w-full rounded-[22px]' />
                        </motion.a>
                    </div>

                </div>
            </div>
            <Footer />
        </div>
    );
}

export default Address;