import TextInBackground from '../../assect/svg/RangenAbout/Rec-03.svg';
import Rectangular from './blurRect.png';
import BackgroundBottom from './backgroundShapesFooter.svg'
import BackgroundPhoneBottom from './backgroundShapesFooterPhone.svg'
import TwoBoxs from './geometriShapes.svg';
import Image from './Image';
import { motion, useAnimation } from 'framer-motion';
import { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';


const ExitPageUs = {
    exit: {
        opacity: 0,
        x: '-100vw',
        transition: {
            ease: 'easeInOut'
        }
    }
}
const TextButton = [
    { icon: 'n', title: 'Manager',styleButton:'Manager', location: 'https://wa.me/message/SBXHGQXEN3I2J1' },
    { icon: 'n', title: 'Designer',styleButton:'Designer', location: 'https://wa.me/message/6YU5WACC6JC5K1' },
    { icon: 'q', title: 'Website',styleButton:'Manager', location: 'http://www.rangeen.net' },
    { icon: 'g', title: 'Email',styleButton:'Manager', location: 'https://mail.zoho.com/zm/#mail/folder/inbox' },
    { icon: 'd', title: 'Telegram',styleButton:'Telegram', location: 'https://t.me/rangeen5m' },
    { icon: 'f', title: 'Facebook',styleButton:'Facebook', location: 'https://www.facebook.com/Rangeen.5M.Printing' },
    { icon: '8', title: 'Location',styleButton:'Manager', location: 'https://goo.gl/maps/JMRsSXaQd1dkGm7SA' },
]
const buttonContainerVariants = {
    hidden: {
        opacity: 1,
    },
    show: {
        opacity: 1,
        transition: {
            staggerChildren: 0.2, // Delay between each button
        },
    },
};
const buttonVariants = {
    hidden: { opacity: 0, x: 40 }, // Initial hidden state for the buttons
    show: { opacity: 1, x: 0 },    // Final state when button is shown
};
const AnimationMarquee = {
    move: {
        y: [10, -10],
        transition: {
            duration: 3.25,
            ease: 'easeInOut',
            repeat: Infinity,
            repeatType: 'reverse'

        }
    }
}
const MainUs = () => {
    const {t} = useTranslation();
    const constrol = useRef();
    const control3 = useAnimation();
    useEffect(() => {
        control3.start('move')
    }, [control3]);
    return (
        <motion.div
            variants={ExitPageUs}
            exit="exit"
            className="w-full 2xl:h-screen md:h-[105vh] h-screen relative overflow-hidden select-none"
            ref={constrol}
        >
            <Image constrolRef={constrol}/>
            <div className="md:mt-20 mt-28 pb-20 flex flex-col justify-between items-center max-sm:gap-y-5 max-xs:gap-y-3 relative">
                <div className="lg:w-[12%] md:w-1/6 sm:w-1/4 w-[29%] relative">
                    <img src={TextInBackground} alt="" className="absolute z-10" />
                    <img src={Rectangular} alt="" className="w-full h-full" />
                </div>
                <p className="font-Rabar_037 sm:text-lg text-sm">{t('Us.title')}</p>
                <p className="font-Rabar_038 sm:text-[16px] text-xs mt-3 mb-4">
                {t('Us.text')}
                </p>

                <motion.div
                    initial="hidden"
                    animate="show"
                    variants={buttonContainerVariants}
                    className="sm:w-80 w-2/3 flex flex-col gap-y-2"
                >
                    {TextButton.map((item, index) => (
                        <motion.button
                            key={index}
                            variants={buttonVariants} // Apply the variants to each button
                            transition={{ duration: 0.5, type: "spring", stiffness: 60 }} // Button transition
                            className={`cursor-none font-Rabar_037 text-xl Background-Us-${item.styleButton} opacity-85 hover:border-[0.2px] border-[0.75px] rounded-[15px] relative hover:bg-Button_US backdrop-blur-[25px]`}
                        >
                            <a href={item.location} className='w-full block py-2'>
                                <span className="absolute max-sm:top-1.5 left-3 top-1 sm:text-3xl text-2xl font-Icon"> {item.icon} </span>
                                <span className="blur-text-Us sm:text-xl xs:text-lg text-center text-sm"> {item.title} </span>
                            </a>

                        </motion.button>
                    ))}
                </motion.div>
            </div>
            <img src={BackgroundBottom} alt="" className="absolute w-full max-sm:hidden bottom-0 -z-30" />
            <img src={BackgroundPhoneBottom} alt="" className="absolute w-full sm:hidden bottom-0 -z-10" />
            <motion.img
                src={TwoBoxs}
                alt=""
                className="absolute sm:bottom-[20%] bottom-[22%] md:-left-10 max-md:-right-5 z-30 w-[30%] h-[30%]"
                variants={AnimationMarquee}
                drag
                dragConstraints={{top:0,bottom:0,left:0,right:0}} 
                animate={control3}
                onDragEnd={() => control3.start('move')} // Restart the animation when dragging ends
                dragElastic={0.5}
            />

        </motion.div>
    );
};

export default MainUs;