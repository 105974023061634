import QRCodeImg from "./QrCode";
import { useState } from "react";
import HasaraSoft from '../../../assect/svg/HasaraSoft.svg'
import { useTranslation } from "react-i18next";
import ChnageType from "./ChangeTypeQrCode";
import ColorButton from "./ColorButton";
import TypeAndImageOfQrCode from "./TypeAndImageOfQrCode";
const PartQrCode = () => {
    const { t, i18n } = useTranslation()
    const [getColor, setColor] = useState({
        typeQrCode: '',
        cornersSquareOptions: '',
        cornersDotOptions: '',
        Dots: '#000000',
        Background: '#ffffff',
        CornersSquare: '#000000',
        cornersDot: '#D31E42',
    });
    const currentLanguage = i18n.language
    const [getURL, setURL] = useState();
    const [getImage, setImage] = useState('');
    const [isHeddin, setIsHeddin] = useState(false);

    
    const EventColor = (event) => {
        const { name, value } = event.currentTarget;
        setColor(prevColors => ({
            ...prevColors,
            [name]: value
        }));
    };

    const HandlingURL = (event) => {
        event.preventDefault();
        setURL(event.target.value);
    };

    return (
        <div className="mt-20 lg:ms-20 md:ms-14  w-4/5 h-auto rounded-[20px] bg-[rgba(255,255,255,0.1)] border-[0.75px] shadow-[0_0_21.1px_0_rgba(0,0,0,0.8),_inset_6px_4px_35.3px_-16px_rgba(255,255,255,0.25)] backdrop-blur-[144.4px] mx-auto" >
            <a href="https://t.me/+2pm-y1rTWkY1NDEy"><img src={HasaraSoft} alt="" className={`absolute bottom-2 ${(currentLanguage === 'en') ? 'right-4' : 'left-4'} sm:w-2/12 w-1/4`}/></a>
            <div className="sm:pe-10 sm:ps-5 font-Rabar_038 lg:text-2xl md:text-xl xs:text-[16px] text-sm py-1 h-auto max-sm:px-5 mx-auto" dir={(currentLanguage === 'en') ? 'ltr' : 'rtl'}>
                <div className=" flex flex-col md:grid md:grid-cols-5 md:grid-rows-QrCode  gap-x-5 max-md:place-items-center">
                    <div className="md:col-start-1 md:col-end-5 md:row-start-1 md:row-end-2 max-md:text-center"  >
                        <p className="border-b-2 py-1 inline-block lg:text-3xl text-2xl" >QR Code</p>
                        <div className="md:py-10 py-5">
                            <p className="inline-block">{t('Design.QrCode.headerQrCode')} </p>
                        </div>
                        <div >
                            <p className="mb-3 md:inline-block lg:text-xl md:text-lg">{t('Design.QrCode.Link')}</p>
                            <input value={getURL} type="url" id="Path" name="PathURL" onChange={HandlingURL} className="text-sm lg:w-1/2 md:w-2/5 lg:h-10 h-7 w-full text-black p-2 md:ms-2 rounded-lg bg-[#BABABA] border-b-4 border-[rgb(212,30,68)]" />
                        </div>
                    </div>
                    <div className="xl:col-start-5 xl:col-end-6 md:col-start-4 md:col-end-6 md:row-start-1 md:row-end-2">
                        <QRCodeImg getColor={getColor} Path={getURL} getImage={getImage} getShow={isHeddin} />
                    </div>
                    <div className="md:col-start-1 md:col-end-4 md:row-start-2 md:row-end-3 max-md:py-7">
                        <p className="font-Rabar_038 max-md:text-center"  >{t('Design.QrCode.ChangeColor')}</p>
                        <ColorButton getColor={getColor} EventColor={EventColor} />
                    </div>
                    <div className="md:col-start-3 md:col-end-6 md:row-start-2 md:row-end-3 md:pt-14 md:py-10 py-1 ">
                        <TypeAndImageOfQrCode isHeddin={isHeddin} setIsHeddin={setIsHeddin} EventColor={EventColor} getColor={getColor} setImage={setImage}/>
                    </div>
                    <div className="md:col-start-1 xl:col-end-5 col-end-6 md:row-start-3 md:row-end-4 py-7 w-full">
                        <ChnageType EventColor={EventColor} />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default PartQrCode;
