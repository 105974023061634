import { Context } from "./MainDesign";
import { useContext } from "react";
import { motion, AnimatePresence } from 'framer-motion'

const Model = () => {
    const [getModel, setModel] = useContext(Context);

    function EventModel() {
        setModel({ Show: false });
    }
    console.log(getModel)

    return (
        <AnimatePresence>
            {getModel.Show && (
                <motion.div
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    exit={{ opacity: 0, scale: 0.1 }}
                    transition={{ duration: 0.5 }}
                    className="fixed inset-0 flex items-center justify-center z-50"
                >
                    <div className="relative bg-[rgba(0,0,0,0.8)] backdrop-blur shadow-lg w-full h-full">
                        <button
                            className="absolute max-md:backdrop-blur-3xl md:top-2 top-5 right-4 p-1 text-5xl font-bold py-4 px-1 border rounded group hover:bg-white"
                            onClick={EventModel}
                        >
                            <div className="w-6 h-0.5 rounded bg-neutral-400 group-hover:bg-black -rotate-45 translate-y-0.5"></div>
                            <div className="w-6 h-0.5 rounded bg-neutral-400 group-hover:bg-black rotate-45"></div>
                        </button>
                        <img src={getModel.getURL} alt="" className="w-auto h-full mx-auto object-contain select-none" />
                    </div>
                </motion.div>
            )}
        </AnimatePresence>
    );
}

export default Model;
