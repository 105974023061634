import { useTypewriter, Cursor } from 'react-simple-typewriter';
import M2 from './M2';
import M1 from './M1';
import { useTranslation } from 'react-i18next';
const Marque = () => {
    const { t, i18n } = useTranslation();
 
    const currentLanguage = i18n.language; 

    const [text] = useTypewriter({
        words: [t('MainPage.Marque.AfterTypeWriter')],
        loop: true, 
        typeSpeed:100,
        delaySpeed:800
    });
    
    return ( 
        <div className='w-full h-auto lg:mt-5 mt-28'>
            <p className='text-center' dir={(currentLanguage==='en')?'ltr':'rtl'}>
            <span className='text-center font-Rabar_037 md:text-2xl xs:text-xl text-sm' >{t('MainPage.Marque.BeforeTypeWriter')} {text} </span>
            <span className='md:text-2xl text-xl text-[rgb(212,30,68)]'><Cursor cursorStyle='|' /></span>
            </p>
            <M1 />
            <M2 />
        </div>
     );
}
 
export default Marque;