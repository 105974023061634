import { useTranslation } from "react-i18next";
const ChnageType = ({EventColor}) => {
    const {t,i18n} = useTranslation();
    const currentLanguage = i18n.language;
    return (
        <div className="flex flex-col gap-y-4 w-full">
            <h1 className="font-Rabar_038 text-lg md:hidden">{t('Design.QrCode.Stylecorners')}</h1>
            <div className="flex max-md:flex-col  gap-5 justify-between items-center w-full">
                <div className="flex items-center md:gap-x-2 max-md:justify-between md:w-full  sm:w-7/12 xs:w-9/12 w-full">
                    <p className={`xl:text-2xl lg:text-xl md:text-sm max-md:hidden ${(currentLanguage==='en')? 'xl:pe-[40px] lg:pe-[34px] md:pe-[22px]' : 'xl:pe-[100px] lg:pe-[84px] md:pe-[62px]'} `} >{t('Design.QrCode.Changecorners')}</p>
                    <button className="cursor-none rounded-[5px] lg:size-[68px] md:size-14 size-[47px] bg-white" name="cornersSquareOptions" value="" onClick={EventColor}></button>
                    <button className="cursor-none rounded-[5px] lg:size-[68px] md:size-14 size-[47px] bg-white" name="cornersSquareOptions" value="square" onClick={EventColor}>
                        <div className="lg:size-12 md:size-10 size-[33px] border-[6px] border-black mx-auto" />
                    </button>
                    <button className="cursor-none rounded-[5px] lg:size-[68px] md:size-14 size-[47px] bg-white" name="cornersSquareOptions" value="extra-rounded" onClick={EventColor}>
                        <div className="lg:size-12 md:size-10 size-[33px] border-[6px] md:rounded-[15px] rounded-xl border-black mx-auto" />
                    </button>
                    <button className="cursor-none rounded-[5px] lg:size-[68px] md:size-14 size-[47px] bg-white" name="cornersSquareOptions" value="dots" onClick={EventColor}>
                        <div className="lg:size-12 md:size-10 size-[33px] border-[6px] rounded-full border-black mx-auto" />
                    </button>
                </div>
            </div>
            <div className="flex max-md:flex-col  gap-5 justify-between items-center w-full">
                <div className="flex items-center md:gap-x-2 max-md:gap-x-8 md:w-full  sm:w-7/12 xs:w-9/12 w-full">
                    <p className="xl:text-2xl lg:text-xl md:text-sm max-md:hidden">{t('Design.QrCode.ChangeIntocorners')}</p>
                    <button className="cursor-none rounded-[5px] lg:size-[68px] md:size-14 size-[47px] bg-white" name="cornersDotOptions" value="" onClick={EventColor}></button>
                    <button className="cursor-none rounded-[5px] lg:size-[68px] md:size-14 size-[47px] bg-white" name="cornersDotOptions" value="square" onClick={EventColor}>
                        <div className="lg:size-12 md:size-10 size-[33px] bg-black mx-auto" />
                    </button>
                    <button className="cursor-none rounded-[5px] lg:size-[68px] md:size-14 size-[47px] bg-white" name="cornersDotOptions" value="dots" onClick={EventColor}>
                        <div className="lg:size-12 md:size-10 size-[33px]  rounded-full bg-black mx-auto" />
                    </button>
                </div>
            </div>
        </div>
    );
}

export default ChnageType;