import { useTranslation } from 'react-i18next';
import Img1 from '../assect/Apps/D1.jpg';
import Img2 from '../assect/Apps/D2.jpg';
import Img3 from '../assect/Apps/D3.jpg';
import Img4 from '../assect/Apps/D4.png';
import Img5 from '../assect/Apps/D5.png';
import Img6 from '../assect/Apps/D6.jpg';
import Img7 from '../assect/Apps/D7.png';

import { Context } from '../MainDesign';
import { useContext, useEffect } from 'react';

const getImage = [
    { styleDiv: "sm:col-start-1 sm:col-end-2 sm:row-start-1 sm:row-end-11 col-start-1 col-end-2 row-start-1 row-end-5", nameImage: Img6, styleImage: " w-full h-full" },
    { styleDiv: "sm:col-start-2 sm:col-end-3 sm:row-start-1 sm:row-end-9 col-start-1 col-end-2 row-start-8 row-end-11", nameImage: Img1, styleImage: " w-full h-full" },
    { styleDiv: "sm:col-start-3 sm:col-end-4 sm:row-start-1 sm:row-end-5 col-start-2 col-end-3 row-start-10 row-end-12", nameImage: Img7, styleImage: " w-full h-full" },
    { styleDiv: "sm:col-start-3 sm:col-end-4 sm:row-start-5 sm:row-end-11 col-start-2 col-end-3 row-start-1 row-end-3", nameImage: Img2, styleImage: " sm:object-cover object-contain h-full sm:w-full w-3/4" },
    { styleDiv: "sm:col-start-4 sm:col-end-5 sm:row-start-1 sm:row-end-5 justify-self-center col-start-1 col-end-2 row-start-11 row-end-13", nameImage: Img4, styleImage: " object-contain h-full w-full" },
    { styleDiv: "sm:col-start-4 sm:col-end-5 sm:row-start-5 sm:row-end-15 sm:justify-self-center col-start-1 col-end-2 row-start-5 row-end-8", nameImage: Img3, styleImage: " sm:h-auto sm:w-auto h-full w-full" },
    { styleDiv: "sm:col-start-5 sm:col-end-6 sm:row-start-1 sm:row-end-6 col-start-2 col-end-3 row-start-7 row-end-10", nameImage: Img5, styleImage: "object-contain sm:w-4/5 h-full w-full" },
    { styleDiv: "sm:col-start-5 sm:col-end-6 sm:row-start-6 sm:row-end-11 col-start-2 col-end-3 row-start-3 row-end-7", nameImage: Img1, styleImage: "sm:w-auto sm:h-auto h-full w-full " },
]

const Teach = () => {
    const [getModel, setModel] = useContext(Context);
    const { t ,i18n} = useTranslation();
    const currentLanguage = i18n.language;

    function EventModel(item) {
        setModel({
            getURL: item,
            Show: true
        })
    }
    useEffect(() => {
        if (getModel.Show) {
            document.body.style.overflow = 'hidden'
        } else {
            document.body.style.overflow = 'auto'
        }

        return () => {
            document.body.style.overflow = 'auto';
        };
    }, [getModel.Show])
    return (
        <div className='mt-24' dir={(currentLanguage==='en')?'ltr':'rtl'}>
            <p className='font-Rabar_038 text-2xl lg:ms-20 md:ms-14 sm:ms-10 ms-7 '>
                <span className='border-b-2 ps-2 pe-4 pb-1'>{t('Design.headerTeach')}</span>
            </p>
            <div className=" sm:ms-2 sm:me-5 mx-5 mt-10 grid sm:gap-2 gap-3 sm:grid-rows-10 sm:grid-cols-5 grid-rows-12 grid-cols-2 mt-10l lg:h-[28rem] md:h-96 sm:h-[17rem] max-xs:h-[50rem] h-[70rem] overflow-hidden select-none" dir='ltr'>
                {
                    getImage.map((item, index) => {
                        return (
                            <div className={item.styleDiv} key={index}>
                                <img src={item.nameImage} alt="6" onClick={() => (EventModel(item.nameImage))} className={item.styleImage} />
                            </div>
                        )
                    })
                }
            </div>
        </div>
    );
};

export default Teach;
