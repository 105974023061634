import Design from "./Design/Design";
import Background from './assect/Design/Design.svg';
import BackgroundPhone from './assect/Design/DesignPhone.svg';
import Apps from "./Apps/Apps";
import Teach from "./Teach/Teach";
import Model from "./Model";

import { createContext , useState } from "react";
import {motion} from 'framer-motion';
import MainQrCode from "./QrCode/MainQrCode";
import { useTranslation } from "react-i18next";

export const Context = createContext();

const ExitDesign = {
    exit:{
        opacity:0,
        x:'100vw',
        transition:{
            ease:'easeInOut'
        }
    }
}

const MainDesign = () => {
    const {i18n} = useTranslation();
    const currentLanguage = i18n.language;
    const [getModel , setModel] = useState({getURL:"",Show:false})
    return ( 
        <Context.Provider value={[getModel , setModel]}>
            <motion.div variants={ExitDesign} exit='exit' className="w-full h-auto relative overflow-hidden" dir={(currentLanguage==='en')?'ltr':'rtl'}>
            <img src={Background} alt="" className="absolute w-full h-auto object-cover lg:-top-36 -top-32  max-sm:hidden"/>
            <img src={BackgroundPhone} alt="" className="absolute w-full object-cover -top-20 sm:hidden"/>
            <div className="relative h-auto w-full lg:mt-36 md:mt-28 mt-24">
                <MainQrCode/>
                <Design />
                <Apps />
                <Teach />
                <Model/>
            </div>
        </motion.div>
        </Context.Provider>
        
     );
}
 
export default MainDesign;