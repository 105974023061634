import Hasarasoft from '../../../assect/svg/HasaraSoft.svg'
const Footer = () => {
    return ( 
        <footer className='w-full h-auto flex flex-col items-center sm:pb-4 pb-2  bg-[rgb(39,39,39)] z-50'>
            <a href="https://t.me/+2pm-y1rTWkY1NDEy" className='md:w-1/5 md:h-1/5 w-1/2 h-1/2'><img src={Hasarasoft} alt="HasaraSoft"/></a>
            <p className='font-Rabar_041 md:text-sm max-md:text-lg max-sm:text-sm' id='text-slide-home'>For Software development</p>
        </footer>
     );
}
 
export default Footer;