import React, { useEffect, useRef, useState } from 'react';
import QRCodeStyling from 'qr-code-styling';
import logo from './photo_2024-09-07_14-05-20.jpg';

const QrCodeImg = ({ getColor, Path, getImage, getShow }) => {
    const qrRef = useRef(null);
    const qrCode = useRef(null);
    const qrCodeDownload = useRef(null);
    const [getFile, setFile] = useState('png');

    useEffect(() => {
        const qrElement = qrRef.current;

        let change = {
            data: Path || "https://www.rangeen.net/",
            image: !getShow ? getImage || logo : null, // Image is shown when isHidden is true, null when false
            imageOptions: {
                crossOrigin: "anonymous",
                margin: 5,
            },
            dotsOptions: {
                color: getColor.Dots,
                type: getColor.typeQrCode,
            },
            backgroundOptions: {
                color: getColor.Background,
            },
            cornersSquareOptions: {
                color: getColor.CornersSquare,
                type: getColor.cornersSquareOptions,
            },
            cornersDotOptions: {
                color: getColor.cornersDot,
                type: getColor.cornersDotOptions,
            },
        }

        qrCode.current = new QRCodeStyling({
            width: 195,
            height: 195,
            ...change
        });
        qrCodeDownload.current = new QRCodeStyling({
            width: 1080,
            height: 1080,
            ...change
            
        });

        if (qrElement) {
            qrCode.current.append(qrElement); 
        }

        return () => {
            if (qrElement) {
                qrElement.innerHTML = ''; // Clean up
            }
        };
    }, [getColor, Path, getImage, getShow]); // Dependencies

    // This useEffect ensures the QR code updates correctly whenever the image or path changes
    useEffect(() => {
        let ToDoUpdate = {
            data: Path || "https://www.rangeen.net/",
            image: !getShow ? getImage || logo : null, // Update image based on isHidden
        }
        if (qrCode.current || qrCodeDownload.current) {
            qrCode.current.update({
                ...ToDoUpdate
            });
            qrCodeDownload.current.update({
                ...ToDoUpdate
            });
        }
    }, [Path, getImage, getShow]);

    const handleDownload = () => {
        qrCodeDownload.current.download({
            name: "QR Code by Rangeen",
            extension: getFile, 
        });
    };

    const HandlingFile = (event) => {
        setFile(event.target.value);
    };

    return (
        <div className='mt-5'>
            <div className='flex flex-col items-center gap-y-4'>
                <div className='rounded-xl overflow-hidden'>
                    <div ref={qrRef} />
                </div>
                <div className='flex gap-x-2'>
                    <select dir="ltr" className='bg-[rgb(212,30,67)] basis-1/3 text-lg rounded-md z-20' onChange={HandlingFile}>
                    {
                        ['png','jpeg','webp','svg'].map((item,index)=>{
                            return(
                                <option value={item} key={index}>{item}</option>
                            )
                        })
                    }
                    </select>
                    <button onClick={handleDownload} className='bg-[rgb(212,30,67)] px-4 text-lg rounded-md z-20 cursor-none'>Download</button>
                </div>
            </div>
        </div>
    );
};

export default QrCodeImg;
