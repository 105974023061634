import { useState } from "react";
import HasaraSoftLogo from '../../assect/svg/HasaraSoft.svg';
import Logo from '../../Logo.svg';
import List from "./List";
import { Link } from "react-router-dom";
import ButtonLanguage from "./ButtonLanguage";

const Navbar = () => {
    const [getMenu, setMenu] = useState(false);

    function EventMenu() {
        setMenu(!getMenu);
    }

    return (
        <div className="md:py-3 xl:px-[42px] lg:px-9 md:px-7 fixed top-0 w-full h-auto right-0 z-40 select-none">
            <nav className="w-full md:h-[65px] h-16 md:px-[22px] pe-[29px] ps-3 bg-[linear-gradient(90deg,_rgba(255,255,255,0.2)_0%,_rgba(102,102,102,0.2)_34%,_rgba(233,233,233,0.2)_100%)]  relative flex justify-between items-center text-white md:rounded-full md:border border-b border-white backdrop-blur-[50.9px] z-50">
                <div className="flex gap-5 items-center">
                    <Link to='/' className="cursor-none"><img src={Logo} alt="Logo Rangeen" className="w-11/12 h-11/12" /></Link>
                    <div className="max-md:hidden pt-2">
                        <ButtonLanguage />
                    </div>
                </div>
                <div className="max-md:hidden ">
                    <List GetStyle="flex flex-row-reverse lg:gap-x-14 gap-x-10 lg:text-xl text-lg " />
                </div>
                <button className="relative md:hidden" onClick={EventMenu}>
                    <div className="w-10 h-1 bg-white rounded-md"></div>
                    <div className="w-9 h-1 bg-white rounded-md my-2 ms-auto"></div>
                    <div className="w-8 h-1 bg-white rounded-md ms-auto"></div>
                </button>
            </nav>
            <div>
                {
                    getMenu &&
                    <div className="w-64 min-h-screen flex flex-col justify-between md:hidden top-[63px] -z-30 right-0 absolute border border-white backdrop-blur-2xl text-white ">
                        <div>
                            <List GetStyle="flex flex-col gap-10 items-center text-2xl mt-[42px]" />
                            <div className="flex justify-center mt-6">
                                <ButtonLanguage />
                            </div>
                        </div>
                        <div className=" mb-28">
                            <p className="text-center mb-1">Powered by:</p>
                            <a href="https://t.me/+2pm-y1rTWkY1NDEy" className="w-9/12 mx-auto"><img src={HasaraSoftLogo} alt="The group of Hasara Soft"  /></a>
                        </div>
                    </div>
                }
            </div>

        </div>
    );
}

export default Navbar;
