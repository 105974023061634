import React from 'react';
import SliderDes from './SliderApps';

import Exe from '../assect/Apps/exeIcon.svg'
import RAR from '../assect/Apps/RARIcon.svg'
import { useTranslation } from 'react-i18next';

const Apps = () => {
    const {t,i18n} = useTranslation();
    const currentLanguage = i18n.language;
    const GetApps = [
        {
            imageIcon: RAR,
            title: 'C4Kurd',
            download: require('../FileDownload/exeElements/c4kud.zip')
        },
        {
            imageIcon: Exe,
            title: 'AnyDisk',
            download: require('../FileDownload/exeElements/AnyDesk(1).exe')
        },
        {
            imageIcon: Exe,
            title: 'QuickLook',
            download: require('../FileDownload/exeElements/QuickLook Installer.exe')
        },
        {
            imageIcon: Exe,
            title: 'Deepfreeze',
            download: '#'
        },
        {
            imageIcon: RAR,
            title: 'bdcam',
            download: require('../FileDownload/exeElements/bdcamsetup.exe')
        },
        {
            imageIcon: RAR,
            title: 'PSDecoder',
            download: require('../FileDownload/exeElements/PSDCodec V1.6 Free Download For Lifetime.rar')
        },
    ]

    return (
        <div className='mt-24' dir={(currentLanguage==='en')?'ltr':'rtl'}>
            <p className='font-Rabar_038 text-2xl lg:ms-20 md:ms-14 sm:ms-10 ms-7'><span className='border-b-2 ps-2 pe-4 pb-1'>{t('Design.headerApp')}</span></p>
            <SliderDes GetApps={GetApps} />
        </div>
    );
};

export default Apps;
