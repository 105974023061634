import RangeLogo_01 from '../../../assect/svg/theRofRangeen.svg';
import RangeLogo_02 from '../../../assect/svg/logoWireframe02.svg';
import ManagerImage from '../../../assect/photo_2022-08-20_18-31-32.jpg'
import { useTranslation } from 'react-i18next';
import {motion} from 'framer-motion';
const AnimationImage = {
    hidden:{
        y:80,
        opacity:0
    },
    visible:{
        y:0,
        opacity:1,
        transition:{
            type:'spring',
            duration:200,
            ease:'easeInOut'
        }
    }
}
const Manager = () => {
    const {t} = useTranslation();
    return ( 
        <div className="w-full h-auto relative md:my-10 my-32 overflow-hidden">
            <img src={RangeLogo_01} alt="" className='absolute object-contain lg:w-full lg:h-full max-sm:w-[30rem] max-sm:h-[30rem] xl:-top-10 xl:-right-[28%] lg:top-1 lg:-right-1/4 md:w-10/12 md:h-10/12 sm:top-[5%] md:-right-[15%] sm:-right-[18%] top-[1%] -right-[13%]'/>
            <img src={RangeLogo_02} alt="" className='absolute lg:left-[22%] lg:bottom-32 md:left-[17%] md:bottom-32 left-[10%] bottom-12 object-cover w-1/4'/>
            <div className='w-full h-full relative flex flex-col  gap-y-4 justify-between items-center md:py-32 py-14 mx-auto'>
                <div className='mb-5'>
                    <p className='font-Rabar_037 lg:text-2xl text-xl'>{t('MainPage.Manager.Manager')}</p>
                </div>
                <motion.div variants={AnimationImage} initial='hidden' whileInView="visible" className='xl:w-1/4 xl:h-1/4 lg:w-[30%] lg:h-[30%] md:w-2/5 md:h-2/5 sm:w-1/2 sm:h-1/2 w-[60%] h-[60%] border-[0.75px] rounded-[20px] border-white bg-[rgba(255,255,255,0.1)] shadow-[0_0_21.1px_0_rgba(0,0,0,0.8),_inset_6px_4px_35.3px_-16px_rgba(255,255,255,0.25)] backdrop-blur-[104px]'>
                    <img src={ManagerImage} alt="" className='p-[29px] rounded-[-9px] object-cover'/>
                </motion.div>
                <div>
                    <p className='font-Rabar_037 lg:text-2xl text-xl '>{t('MainPage.Manager.Name')}</p>
                </div>
            </div>
        </div>
     );
}
 
export default Manager;