import { useEffect } from "react";
import { useLocation } from "react-router-dom";
export const ScrollToTop = () => {
    const { pathname, hash } = useLocation();
  
    useEffect(() => {
      if (!hash) {
        window.scrollTo(0, 0);
      } else {
        const element = document.getElementById(hash.replace("#", ""));
        window.scrollTo(0, 0);
        if (element) {
          const viewportHeight = window.innerHeight;
          // Get the height of the element
          const elementHeight = element.offsetHeight;
          // Calculate the top position to center the element
          const elementTop = element.getBoundingClientRect().top + window.pageYOffset;
          const scrollTop = elementTop - (viewportHeight / 2) + (elementHeight / 2);
          window.scrollTo({ top: scrollTop, behavior: "smooth" });
        }
      }
    }, [pathname, hash]);
  
    return null;
  };
  