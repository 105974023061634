import { useState } from "react";
import { useTranslation } from "react-i18next";
const ButtonLanguage = () => {
    const [showLangauge, setShowLanguage] = useState(false);
    const {i18n} = useTranslation();

    const FlagCountry=i18n.language;

    function HandlingLanguage(event) {
        setShowLanguage(!showLangauge)
        i18n.changeLanguage(event);
    }

    function EventShowLanguage() {
        setShowLanguage(!showLangauge)
    }

    return (
        <div>
            <button onClick={EventShowLanguage} className="cursor-none">
                <img src={require(`./LogoLanguage/${FlagCountry}.png`)} alt="Language Flag" width={30} height={30} />
            </button>
            <div className={`absolute right-10 md:left-32 md:top-16 z-50 flex flex-col w-40 bg-gray-800 rounded-lg ${!showLangauge && 'hidden'}`}>
                {
                    ["ku", "ar", "en"].map((item, index) =>
                    (
                        <button className="flex justify-between items-center py-3 px-3 hover:bg-[#D41E44] rounded-lg" onClick={() => HandlingLanguage(item)} key={index}>
                            <p>{(item === "ku") ? "Kurdish" : (item === "ar") ? "Arabic" : 'English'}</p>
                            <img src={require(`./LogoLanguage/${item}.png`)} alt="Language Flag" width={25} height={25} />
                        </button>
                    )
                    )
                }
            </div>
        </div>
    );
}

export default ButtonLanguage;