import Marquee from 'react-fast-marquee';

const M2 = () => {

    const Marquee2 = [
        {
            GetURL: require('../../../assect/parLogos/M-02/m-01.png'),
            StyleImage: 'lg:me-10 md:mr-4 sm:-mr-11'
        },
        {
            GetURL: require('../../../assect/parLogos/M-02/m-02.png'),
            StyleImage: 'lg:me-10 md:mr-9 max-sm:mr-10'
        },
        {
            GetURL: require('../../../assect/parLogos/M-02/m-03.png'),
            StyleImage: 'lg:me-10 md:mr-9 sm:-mr-4 max-sm:mr-7'
        },
        {
            GetURL: require('../../../assect/parLogos/M-02/m-04.png'),
            StyleImage: 'lg:me-10 md:-mr-4 sm:-mr-28 max-sm:-mr-[72px]'
        },
        {
            GetURL: require('../../../assect/parLogos/M-02/m-05.png'),
            StyleImage: 'lg:me-10 md:mr-9 max-sm:mr-11'
        },
        {
            GetURL: require('../../../assect/parLogos/M-02/m-06.png'),
            StyleImage: 'lg:me-10 md:mr-5 sm:-mr-5 max-sm:mr-5'
        },
        {
            GetURL: require('../../../assect/parLogos/M-02/m-07.png'),
            StyleImage: 'lg:me-10 md:mr-9 sm:-mr-2 max-sm:mr-9'
        },
        {
            GetURL: require('../../../assect/parLogos/M-02/m-08.png'),
            StyleImage: 'lg:me-10 md:mr-9 sm:-mr-4 max-sm:mr-7'
        },
    ];

    return (
        <Marquee className='lg:mt-16 mt-10 lg:mb-10 ' autoFill  >
            {
                Marquee2.map((item, index) => {
                    return (
                        <span className={item.StyleImage} key={index}>
                            <img src={item.GetURL} alt='' className='lg:w-auto lg:h-auto md:w-4/5 md:h-4/5 w-3/5 h-3/5' />
                        </span>
                    )
                })
            }
        </Marquee>
    );
}

export default M2;