import S_I1 from './ComponentImage/complexCircleShapes03.svg'
import S_I2 from './ComponentImage/cubeShape01.svg'
import S_I3 from './ComponentImage/cubeShape02.svg'
import S_I4 from './ComponentImage/cubeShape03.svg'
import S_I5 from './ComponentImage/cubeShape04.svg'
import S_I6 from './ComponentImage/LineRed.svg'
import S_I7 from './ComponentImage/LineWhite.svg'
import S_I8 from './ComponentImage/wireframeCube01.svg'
import S_I9 from './ComponentImage/wireframeCube04.svg'
import S_I11 from './ComponentImage/LineWhite02.svg'
import S_I12 from './ComponentImage/Xs04.svg'

import { useAnimation, motion} from 'framer-motion';
import { useEffect } from 'react'
const Aimation_X_ComplexCir = {
    round: {
        rotate: 90,
        transition: {
            type: 'spring',
            duration: 0.3,
            repeat: Infinity, // Makes the rotation continuous
            repeatType: 'loop', // Ensures the rotation goes from 0 to 360 and back to 0
            ease: 'linear' // Keeps the rotation smooth
        }
    }
}
const GoLineWhite1 = {
    move:{
        x:[-200,window.innerWidth ],
        y:[-200,(window.innerWidth>=700)?window.innerHeight:window.innerHeight/2 ],
        transition:{
            type:'spring',
            duration:5,
            repeat:Infinity,
            repeatType: 'loop',
        }
    }
}
const GoLineRed2 = {
    move: {
      x: [-300, window.innerWidth/3 ],
      y: [-300, (window.innerWidth>=700)? window.innerHeight/1.7:window.innerHeight/6 ],
      transition: {
        type: 'spring',
        duration: 5,
        repeat: Infinity,
        repeatType: 'loop', 
        delay:2
      },
    },
};
const GoLineWhite2 = {
    move: {
      x: [-200, window.innerWidth ],
      y: [-200, (window.innerWidth>=700)?window.innerHeight:window.innerHeight/2 ],
      transition: {
        type: 'spring',
        duration: 5,
        repeat: Infinity,
        repeatType: 'loop', 
        delay:3
      },
    },
};
const AnimationcubeShape = {
    move: {
        y: [-5, 5],
        transition: {
            duration: 3.25,
            ease: 'easeInOut',
            repeat: Infinity,
            repeatType: 'reverse',
            delay:2
        }
    }
}
const AnimationcubeShape1 = {
    move: {
        y: [-7, 7],
        transition: {
            duration: 3.25,
            ease: 'easeInOut',
            repeat: Infinity,
            repeatType: 'reverse'

        }
    }
}

const Image = ({constrolRef}) => {
    const controls1 = useAnimation();
    const controls2 = useAnimation();
    useEffect(()=>{
        controls1.start('move')
        controls2.start('move')
    },[controls1,controls2])
    return ( 
        <div>
            <motion.img src={S_I7} alt=""  variants={GoLineWhite1} animate="move"  className='absolute sm:w-[12%] w-[22%] md:top-32 top-[26%] md:left-10 left-0 -z-30 '/>
            <motion.img  src={S_I4} alt="" drag dragConstraints={{top:0,bottom:0,left:0,right:0}} dragElastic={0.5} className='z-30 absolute  md:top-[116px] top-[34%] md:left-20 left-6 md:w-10 w-6   '/>
            <img src={S_I12} alt=""  className='absolute top-1/4 md:left-[14%] left-16 max-md:opacity-50 w-2.5'/>
            <motion.img src={S_I1}  alt="" variants={Aimation_X_ComplexCir} whileHover="round" className='absolute z-30 md:top-1/3 top-[20%] md:left-32 left-14 w-5'/>
            <motion.img src={S_I1} alt="" variants={Aimation_X_ComplexCir} whileHover="round" className='absolute z-30 md:top-[23%] md:left-[29%] lg:w-[1.5%] w-5 max-md:hidden '/>
            <img src={S_I9} alt="" className='absolute  top-[40%]  md:left-[23%] left-6 md:w-4 w-7'/>
            <img src={S_I8} alt=""  className='absolute  md:top-[53%] md:left-7  w-6 max-md:hidden'/>
            <motion.img src={S_I11} alt="" variants={GoLineWhite2} animate="move"  className='-z-30 absolute sm:w-[10%] w-[20%] md:top-[104px] top-20 md:left-1/4 left-[12%]'/>
            <motion.img src={S_I6}  alt="" variants={GoLineRed2} animate="move" className='-z-40 absolute sm:w-[12%] w-[15%] md:top-48 md:right-[15%] top-[22.5%] right-[16%]'/>
            <motion.img src={S_I1} alt="" variants={Aimation_X_ComplexCir} whileHover="round" className='z-30 absolute sm:bottom-[5%] sm:left-[7%] bottom-7 left-7 w-5 opacity-80'/>
            <motion.img src={S_I12} variants={Aimation_X_ComplexCir} whileHover="round" alt=""  className='absolute lg:bottom-[15%] sm:bottom-[10%] bottom-14 lg:left-1/4 left-5 w-2.5 z-50'/>
            <motion.img src={S_I1} alt="" variants={Aimation_X_ComplexCir} whileHover="round" className='z-30 absolute md:bottom-[16%] bottom-1/4  md:left-[19%] left-5 w-5 opacity-80'/>
            <motion.img src={S_I3} alt="" drag dragConstraints={constrolRef} className='absolute sm:bottom-[25%] z-50 bottom-[22%] sm:left-[22%] left-16 md:w-10 w-6 max-xl:-z-10'/>
            <img src={S_I9} alt=""  className='absolute sm:bottom-1/4 sm:left-[30%] bottom-[16%] left-2 max-sm:w-[5%] rotate-[80deg]'/>
            <motion.img src={S_I5} drag dragConstraints={{top:0,bottom:0,left:0,right:0}} dragElastic={0.5} variants={AnimationcubeShape1} animate={controls1} alt=""  className='z-30 absolute sm:bottom-[15%] sm:right-[15%] bottom-2 right-14 md:w-10 w-6' onDragEnd={() => {controls1.start("move");}}/>
            <motion.img src={S_I1} variants={Aimation_X_ComplexCir} whileHover="round" alt=""  className=' absolute sm:bottom-[21%] bottom-[23%] sm:right-28 right-2 w-5 opacity-80 z-50'/>
            <img src={S_I9} alt="" className='absolute sm:right-10 right-6 md:bottom-[33%] bottom-[12.6%] w-6 rotate-[80deg]'/>
            <motion.img src={S_I2} variants={AnimationcubeShape} drag dragConstraints={{top:0,bottom:0,left:0,right:0}} dragElastic={0.5} animate={controls2}  alt=""  className='z-30 absolute sm:right-[16%] right-14 sm:top-[21%] top-[19%] md:w-9 w-6' onDragEnd={() => {controls2.start("move");}}/>
            <motion.img src={S_I1} alt="" variants={Aimation_X_ComplexCir} whileHover="round" className='z-30 absolute md:right-[17%] md:top-[29%] sm:top-1/3 sm:right-1/4 top-[41%] right-1/3 w-5 opacity-80'/>
            <motion.img src={S_I1} alt="" variants={Aimation_X_ComplexCir} whileHover="round" className='z-30 absolute max-md:hidden right-[20%] top-[55%] w-5 opacity-80'/>
            <img src={S_I12} alt="" className='absolute md:top-1/4 md:right-[21%] top-[31%] right-12 w-2.5'/>
            <img src={S_I8} alt=""  className='absolute md:top-[30%] md:right-[33%] top-[22%] right-[28%] md:w-[1.5%] w-5 rotate-45'/>
            <img src={S_I9} alt=""  className='absolute sm:right-10 right-4 top-[38%] w-9 max-sm:opacity-30'/>
            <motion.img src={S_I12} variants={Aimation_X_ComplexCir} whileHover="round" alt=""  className='absolute sm:top-[45%] bottom-[130px] right-8 sm:right-20 md:w-4 w-2.5 z-50'/>
            <img src={S_I4} alt=""  className='absolute md:hidden top-[35%] right-8 sm:w-6 w-3.5'/>
            <p className='absolute sm:top-16 top-16 md:left-[22%] left-6 md:text-5xl text-4xl'>.</p>
            <p className='absolute md:hidden top-16 left-3 '>.</p>
            <p className='absolute md:hidden top-32 left-9 '>.</p>
            <p className='absolute md:top-[24%] md:left-[17%] top-24 left-20 md:text-5xl text-4xl'>.</p>
            <p className='absolute sm:top-[60px] md:left-[30%] top-16 left-[120px] md:text-5xl text-4xl'>.</p>
            <p className='absolute md:top-28 md:left-[32%] top-[134px] left-[130px] md:text-5xl text-4xl'>.</p>
            <p className='absolute md:top-[19%] md:left-[20%] top-[18.5%] left-[22%]'>.</p> 
            <p className='absolute md:top-[18%] md:left-[26%] top-[88px] left-[35%]'>.</p>
            <p className='absolute md:top-32 top-20 left-[53%]'>.</p> 
            <p className='absolute md:top-[21%] top-24 md:right-16 right-7 md:text-5xl text-4xl'>.</p>
            <p className='absolute md:top-[13%] top-16 md:right-24 right-10 md:text-5xl'>.</p>
            <p className='absolute md:top-[10%] top-14 md:right-[26%] right-1/3 md:text-5xl text-4xl'>.</p>
            <p className='absolute md:top-[16%] top-[88px] right-[39%] md:text-5xl'>.</p>
            <p className='absolute md:top-[10%] top-20 md:right-[148px] right-[84px] md:text-5xl'>.</p>
            <p className='absolute md:top-[19%] md:right-[29%] top-28 right-[120px] '>.</p>
            <p className='absolute md:top-[27%] md:right-[22%] top-[139px] right-[110px]'>.</p>
            <p className='absolute md:hidden top-32 right-[18%] max-md:text-3xl'>.</p>
        </div>
     );
}
 
export default Image;